export const en: { [key: string]: string } = {
  /** Sections **/
  'section.contact': 'Contact',
  'section.features': 'Features',
  'section.references': 'References',
  'section.deployments': 'Our Customers',
  'section.faq': 'FAQ',
  'section.teams': 'Team',
  'section.documents': 'Documents',
  'section.resources': 'Want to learn more?',
  'section.document-lifecycle': 'Document Lifecycle',

  /** Common */
  error: 'Oops, something went wrong...',
  'error.button': 'Reload page',

  'mild-blue.coop': 'Created in collaboration with',
};

export type LanguageStrings = typeof en;
