import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { StyledMenuLink } from '../../components/StyledMenuLink';
import { Device } from '../../constants/device';
import { AppLanguage } from '../../model/enums/AppLanguage';
import { appTranslations } from '../index';
import { Colors } from '../../constants/colors';

const StyledLanguageSwitcher = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  ${StyledMenuLink} {
    font-weight: 400;
    border-bottom: 1px solid;
    padding: 0;

    ${Device.desktop} {
      border-bottom: none;
    }
  }
`;

export const LanguageSwitcher: React.FC = () => {
  const { locale } = useIntl();
  const { pathname } = useLocation();
  const { messages } = useIntl();

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  const getMatchingRoute = (language: string) => {
    /**
     * Get the key of the route the user is currently on
     */
    const route = pathname.substring(3); // remove local part '/en' from the pathname /en/contact
    const routeKey = Object.keys(messages).find((key) => messages[key] === route);

    /**
     * Find the matching route for the new language
     */
    const matchingRoute = routeKey ? appTranslations[language][routeKey] : '';

    /**
     * Return localized route
     */
    return `/${language}` + matchingRoute;
  };

  const getLabel = (language: AppLanguage) => {
    switch (language) {
      case AppLanguage.Czech:
        return 'Čeština';
      case AppLanguage.English:
        return 'English';
      default:
        return '';
    }
  };

  return (
    <StyledLanguageSwitcher>
      {Object.values(AppLanguage).map((lang: string) => {
        if (lang === locale) {
          return null;
        }
        return (
          <li key={lang}>
            <StyledMenuLink as={Link} to={getMatchingRoute(lang)} color={Colors.primary}>
              {getLabel(lang as AppLanguage)}
            </StyledMenuLink>
          </li>
        );
      })}
    </StyledLanguageSwitcher>
  );
};
