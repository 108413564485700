import { LanguageStrings } from './en';

export const cs: LanguageStrings = {
  /** Sections **/
  'section.contact': 'Kontakt',
  'section.features': 'Funkce',
  'section.references': 'Reference',
  'section.deployments': 'Uživatelé slp.blue',
  'section.faq': 'FAQ',
  'section.teams': 'Tým',
  'section.documents': 'Dokumenty',
  'section.resources': 'Chcete vědět víc?',
  'section.document-lifecycle': 'Proces zpracování dokumentu',

  /** Common */
  error: 'Jejda, něco se pokazilo...',
  'error.button': 'Znovu načíst stránku',

  'mild-blue.coop': 'Vytvářeno ve spolupráci s',
};
