import { ProjectSectionProps } from '../../model/ProjectSection';
import React, { ReactElement, useState } from 'react';
import { useAppContext } from '../../state/StateProvider';
import { Section } from '../Section';
import { Container } from '../Container';
import { useIsMobile } from '../../helpers/useIsMobile';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import markerIcon from '../../../assets/images/marker-icon.png';
import shadowIcon from '../../../assets/images/marker-shadow.png';
import { darken } from 'polished';
import { MaximizeIcon } from '../icons/MaximizeIcon';
import { CloseIcon } from '../icons/CloseIcon';
import { Device } from '../../constants/device';

export const Deployments = (props: ProjectSectionProps): ReactElement | null => {
  const {
    state: { project },
  } = useAppContext();
  if (!project?.deployments) return null;

  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const isMobile = useIsMobile();
  const mapItems = [...project.deployments.mapOnlyDeployments, ...project.deployments.standaloneDeployments];
  const logos = [...project.deployments.logosOnlyDeployments, ...project.deployments.standaloneDeployments];

  L.Marker.prototype.setIcon(
    L.icon({
      iconUrl: markerIcon,
      shadowUrl: shadowIcon,
      iconSize: [24, 36],
      iconAnchor: [12, 36],
      shadowAnchor: [12, 40],
      popupAnchor: [0, -20],
    }),
  );

  return (
    <Section id={'deployments'} inverse={props.inverse}>
      <Container>
        <DeploymentsContainer>
          <InfoContainer>
            <Disclaimer dangerouslySetInnerHTML={{ __html: project.deployments.disclaimer }} />
            <Divider />
            <DeploymentsList>
              {logos.map((item, index) => (
                <DeploymentItem key={index} href={item.url} target="_blank">
                  <img src={`/deployments/${item.photo}`} alt={item.name} />
                  <DeploymentOverlay className="overlay">{item.name}</DeploymentOverlay>
                </DeploymentItem>
              ))}
            </DeploymentsList>
          </InfoContainer>
          {!isMobile && (
            <>
              {project.deployments.mapTitle}
              <DesktopDeploymentsContainer>
                <MapContainer center={[49.5, 16.9]} zoom={window.innerWidth > 1100 ? 7.5 : 7.25} style={{ height: '100%', width: '100%' }} zoomSnap={0.25} wheelPxPerZoomLevel={200}>
                  <DialogButton onClick={() => setOpen(true)} zIndex={400}>
                    <MaximizeIcon />
                  </DialogButton>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    tileSize={256}
                  />
                  {mapItems.map((item, index) => {
                    if (item.latitude && item.longitude) {
                      return (
                        <Marker position={[item.latitude, item.longitude]} key={index}>
                          <Popup>
                            <DeploymentItem key={index} href={item.url} target="_blank">
                              <img src={`/deployments/${item.photo}`} alt={item.name} />
                              <DeploymentOverlay className="overlay">{item.name}</DeploymentOverlay>
                            </DeploymentItem>
                          </Popup>
                        </Marker>
                      );
                    }
                  })}
                </MapContainer>
              </DesktopDeploymentsContainer>
            </>
          )}
        </DeploymentsContainer>
      </Container>
      {open && (
        <DialogOverlay onClick={handleClose}>
          <Dialog onClick={(e) => e.stopPropagation()}>
            <DialogButton onClick={handleClose} zIndex={1000}>
              <CloseIcon />
            </DialogButton>
            <MapContainer center={[49.82, 15.48]} zoom={8.25} zoomSnap={0.25} style={{ height: '90vh', width: '100%' }}>
              <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {mapItems.map((item, index) => {
                if (item.latitude && item.longitude) {
                  return (
                    <Marker position={[item.latitude, item.longitude]} key={index}>
                      <Popup>
                        <DeploymentItem key={index} href={item.url} target="_blank">
                          <img src={`/deployments/${item.photo}`} alt={item.name} />
                          <DeploymentOverlay className="overlay">{item.name}</DeploymentOverlay>
                        </DeploymentItem>
                      </Popup>
                    </Marker>
                  );
                }
              })}
            </MapContainer>
          </Dialog>
        </DialogOverlay>
      )}
    </Section>
  );
};

const DeploymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const Disclaimer = styled.p`
  text-align: start !important;
  flex: 1;

  ${Device.laptop} {
    flex: none;
    max-width: 35%;
  }

  ${Device.tablet} {
    max-width: none;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  ${Device.tablet} {
    flex-direction: column;
    gap: 0;
  }
`;

const Divider = styled.div`
  background: ${Colors.primaryDark};
  height: 300px;
  width: 3px;
  border-radius: 5px;

  ${Device.laptop} {
    display: none;
  }
`;

const DesktopDeploymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 650px;
  gap: 20px;
  text-align: center;
  width: 100%;

  .leaflet-top {
    z-index: 500;
  }

  .leaflet-bottom {
    z-index: 500;
  }
`;

const DeploymentsList = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

const DeploymentItem = styled.a`
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 10px;

  img {
    height: 35px;
    transition: opacity 0.3s ease;
  }

  &:hover img {
    opacity: 0.5;
  }

  &:hover .overlay {
    opacity: 1;
  }
`;

const DeploymentOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  color: ${Colors.primaryDark};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  text-align: center;
  font-size: 14px;
`;

const DialogButton = styled.button<{ zIndex: number }>`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 4px;
  background-color: ${Colors.white};
  color: ${Colors.black};
  cursor: pointer;
  z-index: ${(props) => props.zIndex};
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: ${darken(0.1, Colors.white)};
  }
`;

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Dialog = styled.div`
  position: relative;
  background-color: white;
  border-radius: 8px;
  width: 90vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
