import React, { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppContext } from '../state/StateProvider';
import { Container } from './Container';
import { LanguageSwitcher } from '../i18n/components/LanguageSwitcher';
import { Logo } from './Logo';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Sizes } from '../constants/sizes';
import { AnchoredLink } from './AnchoredLink';
import { Device, DeviceWidth } from '../constants/device';
import menuIcon from '../../assets/images/menu.svg';
import closeIcon from '../../assets/images/close.svg';
import { Banner } from './Banner';
import { StyledMenuLink } from './StyledMenuLink';
import { useIsNarrowerThan } from '../helpers/useIsMobile';

export const Header = (): ReactElement | null => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isNarrowerThanDesktop = useIsNarrowerThan(DeviceWidth.desktop);

  const {
    state: { project },
  } = useAppContext();
  const primaryLinks = ['features', 'references'];
  const secondaryLinks = ['faq', 'teams', 'contact'];

  return (
    <FixedHeader>
      <Banner />
      <Container>
        <HeaderContent>
          <Logo project={project} />
          <HeaderContentMiddle className={'styled-navigation'}>
            <NavLinks>
              {primaryLinks.map((section, key) => (
                <AnchoredLink key={key} anchor={section} color={Colors.primary}>
                  <FormattedMessage id={'section.' + section} />
                </AnchoredLink>
              ))}
              {secondaryLinks.map((section, key) => (
                <AnchoredLink key={key} anchor={section} color={isNarrowerThanDesktop ? Colors.primary : Colors.white}>
                  <FormattedMessage id={'section.' + section} />
                </AnchoredLink>
              ))}
              <StyledMenuLink href={project?.resources.handbookButton.link} target={'_blank'} rel="noopener noreferrer" color={isNarrowerThanDesktop ? Colors.primary : Colors.white}>
                {project?.resources.handbookButton.shortLabel}
              </StyledMenuLink>
            </NavLinks>
          </HeaderContentMiddle>
          <HeaderContentEnd>
            <HeaderDivider />
            <LanguageSwitcher />
          </HeaderContentEnd>
          <Burger onClick={() => setShowMobileMenu((show) => !show)}>
            <img src={menuIcon} alt="Menu icon" />
          </Burger>

          {showMobileMenu && (
            <MobileMenu>
              <MobileHeaderContent className={'mobileHeaderContent'}>
                <Logo project={project} />
                <Burger onClick={() => setShowMobileMenu((show) => !show)}>
                  <img src={closeIcon} alt="Close icon" />
                </Burger>
              </MobileHeaderContent>
              <NavContainer>
                <nav>
                  {[...primaryLinks, ...secondaryLinks].map((section, key) => (
                    <AnchoredLink key={key} anchor={section} onClick={() => setShowMobileMenu(false)} color={Colors.primary}>
                      <FormattedMessage id={'section.' + section} />
                    </AnchoredLink>
                  ))}
                </nav>
                <LanguageSwitcher />
              </NavContainer>
            </MobileMenu>
          )}
        </HeaderContent>
      </Container>
    </FixedHeader>
  );
};

const FixedHeader = styled.header`
  width: 100%;
  z-index: 10;
`;

const Burger = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: none;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  ${Device.mobile} {
    height: 20px;
    width: 20px;
  }

  img {
    width: 100%;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  height: ${Sizes.HeaderHeight}px;

  ${Device.laptop} {
    align-items: center;
  }

  ${Burger} {
    display: none;

    ${Device.laptop} {
      display: block;
    }
  }
`;

const HeaderContentMiddle = styled.nav`
  display: flex;
  flex: 1;

  ${Device.laptop} {
    display: none;
  }
`;

const HeaderContentEnd = styled.div`
  display: flex;

  ${Device.laptop} {
    display: none;
  }
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 800px;
  margin-left: 210px;

  ${Device.fullHd} {
    margin-left: 85px;
  }

  ${Device.desktop} {
    width: 100%;
    justify-content: end;
    gap: 50px;
    margin-right: 20px;
  }
`;

const HeaderDivider = styled.div`
  width: 2px;
  height: 25px;
  background: ${Colors.primary};
  margin-right: 20px;
  padding-top: 15px;
  display: none;

  ${Device.desktop} {
    display: flex;
  }

  ${Device.laptop} {
    display: none;
  }
`;

const MobileHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${Sizes.HeaderHeight}px;
  padding: 0 30px;
  width: 100%;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding-bottom: ${Sizes.HeaderHeight}px;
`;

const MobileMenu = styled.div`
  position: fixed;
  z-index: 100;
  background: ${Colors.white};
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
  }

  a {
    color: ${Colors.primary};
    margin: 10px 0;
  }
`;
