import React from 'react';

export const ArrowIcon = () => (
  <svg width="20" height="51" viewBox="0 0 20 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4.45903 19.4692C5.07107 18.2213 6.06338 18.2213 6.67542 19.4692L11.1082 28.5072L15.541 37.5451C16.153 38.793 16.153 40.8162 15.541 42.0641C14.9289 43.312 13.9366 43.312 13.3246 42.0641L4.45903 23.9882C3.84699 22.7403 3.84699 20.7171 4.45903 19.4692Z"
        fill="white"
      />
      <path
        d="M15.541 0.93591C16.153 2.18379 16.153 4.207 15.541 5.45488L6.67542 23.5308C6.06338 24.7787 5.07107 24.7787 4.45903 23.5308C3.84699 22.2829 3.84699 20.2597 4.45903 19.0118L13.3246 0.935911C13.9366 -0.31197 14.9289 -0.31197 15.541 0.93591Z"
        fill="white"
      />
    </g>
  </svg>
);
