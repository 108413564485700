import React, { ReactElement, useState } from 'react';
import { useAppContext } from '../../state/StateProvider';
import styled, { keyframes } from 'styled-components';
import { Section } from '../Section';
import { ProjectSectionProps } from '../../model/ProjectSection';
import { Button } from '../buttons/Button';
import { Colors } from '../../constants/colors';
import { Container } from '../Container';
import { Device } from '../../constants/device';
import { SendIcon } from '../icons/SendIcon';
import { sanitize } from 'dompurify';

enum SubmissionStatus {
  Idle,
  Success,
  Error,
}

export const Contact = (props: ProjectSectionProps): ReactElement | null => {
  const {
    state: { project },
  } = useAppContext();

  if (!project) {
    return null;
  }

  const portalId = '139607478';
  const formId = 'f7826b55-2334-4228-9eea-d269015b9656';

  const [formValues, setFormValues] = useState({
    email: '',
    name: '',
    company: '',
    message: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>(SubmissionStatus.Idle);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setSubmissionStatus(SubmissionStatus.Idle);
    try {
      const response = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fields: [
            { name: 'email', value: formValues.email },
            { name: 'full_name_with_titles', value: formValues.name },
            { name: 'company', value: formValues.company },
            { name: 'message', value: formValues.message },
          ],
          context: {
            pageUri: window.location.href,
            pageName: 'slp.blue web',
          },
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to submit form: ${response.status}`);
      }

      setSubmissionStatus(SubmissionStatus.Success);
      setFormValues({
        email: '',
        name: '',
        company: '',
        message: '',
      });
    } catch (error) {
      console.error(error);
      setSubmissionStatus(SubmissionStatus.Error);
    }
  };

  const isFormValid = formValues.email.trim() !== '' && formValues.name.trim() !== '' && formValues.company.trim() !== '' && formValues.message.trim() !== '';

  return (
    <Section id="contact" inverse={props.inverse} customHeading={'Kontaktujte nás'}>
      <Container>
        {project.contactNote && <ContactNote dangerouslySetInnerHTML={{ __html: sanitize(project.contactNote) }} />}
        <FormContainer>
          <FormHeader>
            <h1>{project.contactForm.header}</h1>
            {submissionStatus === SubmissionStatus.Success && <>✔ {project.contactForm.success}</>}
            {submissionStatus === SubmissionStatus.Error && <ErrorShake>✘ {project.contactForm.error}</ErrorShake>}
          </FormHeader>
          <ContactForm>
            <Input type={'text'} name={'name'} placeholder={project.contactForm.name} value={formValues.name} onChange={handleChange} />
            <Input type={'email'} name={'email'} placeholder={project.contactForm.email} value={formValues.email} onChange={handleChange} />
            <Input type={'text'} name={'company'} placeholder={project.contactForm.company} value={formValues.company} onChange={handleChange} />
            <Textarea name={'message'} placeholder={project.contactForm.message} value={formValues.message} onChange={handleChange} rows={4} />
          </ContactForm>
          <SubmitButtonWrapper>
            <Button onClick={handleSubmit} inverse disabled={!isFormValid}>
              {project.contactForm.submit}
              <IconWrapper>
                <SendIcon />
              </IconWrapper>
            </Button>
          </SubmitButtonWrapper>
        </FormContainer>
      </Container>
    </Section>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 20px;
  gap: 20px;
  background: ${Colors.primary};
  color: ${Colors.white};
  align-self: center;
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;
`;

const ContactNote = styled.p`
  max-width: 1100px;
  align-self: center;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${Device.tablet} {
    flex-direction: column;
    text-align: center;
  }
`;

const SubmitButtonWrapper = styled.div`
  align-self: flex-end;

  ${Device.mobile} {
    width: 100%;
  }
`;

const ContactForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: 20px;

  & > textarea {
    grid-column: 1 / span 3;
  }

  ${Device.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    & > textarea {
      grid-column: 1;
    }
  }
`;

const Input = styled.input`
  padding: 10px;
  font: inherit;
  border: none;
  outline: none;
  border-radius: 4px;
  min-width: 50px;
`;

const Textarea = styled.textarea`
  grid-column: 1 / span 3;
  padding: 10px;
  font: inherit;
  border: none;
  outline: none;
  border-radius: 4px;
  resize: none;
  min-width: 50px;

  ${Device.tablet} {
    grid-column: 1;
  }
`;

const IconWrapper = styled.div`
  height: 20px;
  display: flex;

  svg {
    height: 100%;
    width: auto;
    stroke: ${Colors.primary};
  }
`;

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-6px);
  }
  40% {
    transform: translateX(6px);
  }
  60% {
    transform: translateX(-6px);
  }
  80% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(0);
  }
`;

const ErrorShake = styled.span`
  animation: ${shakeAnimation} 0.4s ease-in-out;
`;
