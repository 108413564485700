import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Colors } from '../constants/colors';
import { Device } from '../constants/device';
import { ProjectSection } from '../model/ProjectSection';

type SectionProps = {
  id?: ProjectSection;
  inverse?: boolean;
  children?: ReactNode;
  hideHeading?: boolean;
  customHeading?: string;
};

type StyledSectionProps = {
  hasHeading: boolean;
};

export const Section = (props: SectionProps) => {
  const heading = props.customHeading ?? 'section.' + props.id;
  return (
    <StyledSection id={props.id} hasHeading={!!heading} className={props.inverse ? 'inverse' : ''}>
      {heading && !props.hideHeading && (
        <>
          <h2>
            <FormattedMessage id={heading} />
          </h2>
          <SmallDividingLine />
        </>
      )}
      {props.children}
    </StyledSection>
  );
};

const StyledSection = styled.section<StyledSectionProps>`
  padding: ${(props) => (props.hasHeading ? '80px 0 100px' : '0 !important')};
  overflow: hidden;

  ${Device.tablet} {
    padding: 40px 0 60px;
  }

  &.inverse {
    background: ${Colors.white};
  }

  h2 {
    text-align: center;
    position: relative;
    margin-bottom: 10px;
  }

  p {
    line-height: 2;
    text-align: center;

    ${Device.tablet} {
      text-align: left;
    }
  }
`;

const SmallDividingLine = styled.div`
  background: ${Colors.primary};
  height: 5px;
  width: 50px;
  left: 50%;
  margin-bottom: 40px;
  margin-left: -25px;
  display: block;
  position: relative;
`;
