import {
  ContactFormInterface,
  DeploymentsInterface,
  DocumentLifecycleInterface,
  FAQInterface,
  ProjectBulletPointInterface,
  ProjectInterface,
  ReferenceInterface,
  ResourcesInterface,
  TeamInterface,
  TeamMemberInterface,
} from '../../model/ProjectInterface';
import { ContactForm, Deployments, FAQ, Project, ProjectBulletPoint, Reference, Resources, Team, TeamMember, DocumentLifecycle } from '../../model/Project';
import { AppLanguage } from '../../model/enums/AppLanguage';
import { ProjectFeature } from '../../model/enums/ProjectFeature';

export const translateProject = (data: ProjectInterface, locale: string): Project => {
  const points = data.points ? data.points.map((p) => translateProjectPoint(p, locale)) : [];
  const faq = data.faq ? translateFAQ(data.faq, locale) : undefined;
  const teams = data.teams ? data.teams.map((team) => translateTeam(team, locale)) : [];
  const references = data.references.map((reference) => parseReferences(reference, locale));
  const deployments = translateDeployments(data.deployments, locale);
  const resources = translateResources(data.resources, locale);
  const contactForm = translateContactForm(data.contactForm, locale);
  const documentLifecycle = translateDocumentLifecycle(data.document_lifecycle, locale);

  if (locale === AppLanguage.Czech) {
    return {
      ...data,
      name: data.name_cs ?? '',
      subtitle: data.subtitle_cs ?? '',
      about: data.about_cs ?? [],
      transition: data.transition_cs ?? [],
      contactNote: data.contact_note_cs,
      billingAddress: data.billing_address_cs,
      banner: data.banner_cs ?? '',
      fundingNote: data.funding_note_cs,
      points,
      faq,
      teams,
      documentsLabel: data.documents_label_cs,
      documents: data.documents_cs,
      references,
      deployments,
      resources,
      contactForm,
      documentLifecycle,
    };
  }

  return {
    ...data,
    name: data.name_en ?? '',
    subtitle: data.subtitle_en ?? '',
    about: data.about_en ?? [],
    transition: data.transition_en ?? [],
    contactNote: data.contact_note_en,
    billingAddress: data.billing_address_en,
    banner: data.banner_en ?? '',
    fundingNote: data.funding_note_en,
    documentsLabel: data.documents_label_en,
    documents: data.documents_en,
    points,
    faq,
    teams,
    references,
    deployments,
    resources,
    contactForm,
    documentLifecycle,
  };
};

export const translateProjectPoint = (data: ProjectBulletPointInterface, locale: string): ProjectBulletPoint => {
  if (locale === AppLanguage.Czech) {
    return {
      key: data.key as ProjectFeature,
      title: data.title_cs,
      description: data.description_cs,
    };
  }

  return {
    key: data.key as ProjectFeature,
    title: data.title_en,
    description: data.description_en,
  };
};

export const translateFAQ = (data: FAQInterface, locale: string): FAQ => {
  return {
    questions: data.questions.map((q) => ({
      question: locale === AppLanguage.Czech ? q.question_cs : q.question_en,
      answer: locale === AppLanguage.Czech ? q.answer_cs : q.answer_en,
    })),
    disclaimer: locale === AppLanguage.Czech ? data.disclaimer_cs : data.disclaimer_en,
  };
};

export const translateTeam = (data: TeamInterface, locale: string): Team => {
  if (locale === AppLanguage.Czech) {
    return {
      title: data.title_cs,
      teamMembers: data.members.map((member) => translateTeamMember(member, locale)),
    };
  }

  return {
    title: data.title_en,
    teamMembers: data.members.map((member) => translateTeamMember(member, locale)),
  };
};

const translateTeamMember = (data: TeamMemberInterface, locale: string): TeamMember => {
  if (locale === AppLanguage.Czech) {
    return {
      ...data,
      name: data.name_cs,
      positions: data.positions_cs,
    };
  }

  return {
    ...data,
    name: data.name_en,
    positions: data.positions_en,
  };
};

const parseReferences = (data: ReferenceInterface, locale: string): Reference => {
  if (locale === AppLanguage.Czech) {
    return {
      name: data.name,
      photo: data.photo,
      position: data.position_cs,
      company: data.company,
      text: data.text_cs,
    };
  }
  return {
    name: data.name,
    photo: data.photo,
    position: data.position_en,
    company: data.company,
    text: data.text_en,
  };
};

const translateDeployments = (data: DeploymentsInterface, locale: string): Deployments => {
  return {
    standaloneDeployments: data.standalone_deployments,
    mapOnlyDeployments: data.map_only_deployments,
    logosOnlyDeployments: data.logos_only_deployments,
    disclaimer: locale === AppLanguage.Czech ? data.disclaimer_cs : data.disclaimer_en,
    mapTitle: locale === AppLanguage.Czech ? data.map_title_cs : data.map_title_en,
  };
};

const translateResources = (data: ResourcesInterface, locale: string): Resources => {
  if (locale === AppLanguage.Czech) {
    return {
      newsletterNote: data.newsletter_note_cs,
      handbookNote: data.handbook_note_cs,
      newsletterButton: {
        label: data.newsletter_button_label_cs,
        link: data.newsletter_button_link,
      },
      handbookButton: {
        label: data.handbook_button_label_cs,
        link: data.handbook_button_link,
        shortLabel: data.handbook_button_label_short_cs,
      },
    };
  }
  return {
    newsletterNote: data.newsletter_note_en,
    handbookNote: data.handbook_note_en,
    newsletterButton: {
      label: data.newsletter_button_label_en,
      link: data.newsletter_button_link,
    },
    handbookButton: {
      label: data.handbook_button_label_en,
      link: data.handbook_button_link,
      shortLabel: data.handbook_button_label_short_en,
    },
  };
};

const translateContactForm = (data: ContactFormInterface, locale: string): ContactForm => {
  if (locale == AppLanguage.Czech) {
    return {
      header: data.header_cs,
      name: data.name_cs,
      email: data.email,
      company: data.company_cs,
      message: data.message_cs,
      submit: data.submit_cs,
      success: data.success_cs,
      error: data.error_cs,
    };
  }
  return {
    header: data.header_en,
    name: data.name_en,
    email: data.email,
    company: data.company_en,
    message: data.message_en,
    submit: data.submit_en,
    success: data.success_en,
    error: data.error_en,
  };
};

const translateDocumentLifecycle = (data: DocumentLifecycleInterface, locale: string): DocumentLifecycle => {
  if (locale == AppLanguage.Czech) {
    return {
      desktopDiagram: data.desktop_diagram_cs,
      tabletDiagram: data.tablet_diagram_cs,
      mobileDiagram: data.mobile_diagram_cs,
    };
  }

  return {
    desktopDiagram: data.desktop_diagram_en,
    tabletDiagram: data.tablet_diagram_en,
    mobileDiagram: data.mobile_diagram_en,
  };
};
