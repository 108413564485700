import { createGlobalStyle } from 'styled-components';
import { FontFace } from './fontFace';
import { Colors } from '../app/constants/colors';
import { Fonts } from '../app/constants/fonts';
import { cookiesStyles } from './cookiesStyles';

const GlobalStyle = createGlobalStyle`
  ${FontFace}
  ${cookiesStyles}
  body {
    margin: 0;
    padding: 0;
    color: ${Colors.text};
    font-family: ${Fonts.base};
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;

    * {
      box-sizing: border-box;
    }
  }

  main {
    background: ${Colors.background};
  }

  h1, h2, h3, h4, h5 {
    font-family: ${Fonts.secondary};
  }

  html, body, #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  button {
    cursor: pointer;
  }
`;

export default GlobalStyle;
