import React, { MouseEvent, ReactElement, ReactNode } from 'react';
import { StyledMenuLink } from './StyledMenuLink';

type AnchoredLinkProps = {
  anchor: string;
  children: ReactNode;
  onClick?: () => void;
  color: string;
};

export const AnchoredLink = (props: AnchoredLinkProps): ReactElement => {
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    const anchorTarget = document.getElementById(props.anchor);

    if (anchorTarget) {
      anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <StyledMenuLink href={`#${props.anchor}`} onClick={handleClick} color={props.color}>
      {props.children}
    </StyledMenuLink>
  );
};
