import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Fonts } from '../constants/fonts';
import React from 'react';
import { Project } from '../model/Project';
import { Colors } from '../constants/colors';
import { ThemedLogo } from './ThemedLogo';

export const Logo = ({ project }: { project?: Project }): JSX.Element => {
  return (
    <StyledLogoLink to="/">
      <ThemedLogo />
      <div>{project && <div className="project">{project.name}</div>}</div>
    </StyledLogoLink>
  );
};

const StyledLogoLink = styled(Link)`
  font-family: ${Fonts.secondary};
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${Colors.primary};
  line-height: 1;

  .company {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
  }

  .project {
    font-size: 24px;
    font-weight: 900;
  }
`;
