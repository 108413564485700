import { useState, useEffect } from 'react';

export const useIsMobile = () => {
  return useIsNarrowerThan(726);
};

export const useIsNarrowerThan = (breakpoint: number) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth <= breakpoint);
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return isMobile;
};
