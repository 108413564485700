import React, { ReactElement, useRef, useState } from 'react';
import { useAppContext } from '../state/StateProvider';
import { Container } from './Container';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Sizes } from '../constants/sizes';
import { Device, DeviceWidth } from '../constants/device';
import Slider from 'react-slick';
import { ArrowIcon } from './icons/ArrowIcon';

export const Jumbotron = (): ReactElement | null => {
  const {
    state: { project },
  } = useAppContext();
  if (!project) return null;

  const pictures = ['dokumenty', 'schvalovani', 'verifikace', 'pozadavky', 'agendy'];
  const totalSlides = pictures.length;
  const [imageIndex, setImageIndex] = useState<number>(0);
  const sliderRef = useRef<Slider>(null);
  const showMobileCarousel = window.innerWidth <= DeviceWidth.laptop || (window.innerWidth > DeviceWidth.desktop && window.innerWidth <= 1918);

  const carouselSettings = {
    infinite: true,
    speed: 300,
    slidesToShow: showMobileCarousel ? 1 : 3,
    centerMode: true,
    centerPadding: window.innerWidth <= 768 ? '0' : '40px',
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    // autoplay: true,
    pauseOnHover: true,
    beforeChange: (current: number, next: number) => setImageIndex(next),
  };

  const handleSlideClick = (index: number) => {
    if (!sliderRef.current || index === imageIndex) return;

    const isNext = index === (imageIndex + 1) % totalSlides;
    const isPrev = index === (imageIndex - 1 + totalSlides) % totalSlides;

    if (isNext) sliderRef.current.slickNext();
    else if (isPrev) sliderRef.current.slickPrev();
    else sliderRef.current.slickGoTo(index);
  };

  const cycDist = (idx: number, activeIndex: number): number => {
    return (idx - activeIndex + totalSlides) % totalSlides;
  };

  return (
    <StyledJumbotron className={'styledJumbotron'}>
      <Container>
        <JumbotronLeft>
          <h1>{project.name}</h1>
          <DividingLine />
          <p>{project.subtitle}</p>
        </JumbotronLeft>
        <JumbotronRight className={'jumbotronRight'}>
          <CarouselWrapper className={'carouselWrapper'}>
            <Slider {...carouselSettings} ref={sliderRef}>
              {pictures.map((item, idx) => {
                const distance = cycDist(idx, imageIndex);
                let translateX = 0;
                let opacity = 1;

                if (distance === 0) {
                  translateX = 0;
                } else if (distance === 1) {
                  translateX = 100;
                } else if (distance === totalSlides - 1) {
                  translateX = -100;
                } else {
                  opacity = 0;
                }

                return (
                  <CarouselItem key={idx} isActive={distance === 0} translate={translateX} opacity={opacity} onClick={() => handleSlideClick(idx)}>
                    <CarouselImage src={`/features/${item}.png`} alt={String(idx)} />
                  </CarouselItem>
                );
              })}
            </Slider>
            <Navigation>
              <ArrowButton onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
                <ArrowIcon />
              </ArrowButton>
              <ArrowButton onClick={() => sliderRef.current && sliderRef.current.slickNext()} mirror>
                <ArrowIcon />
              </ArrowButton>
            </Navigation>
          </CarouselWrapper>
          <BlueStripe />
        </JumbotronRight>
      </Container>
    </StyledJumbotron>
  );
};

const CarouselItem = styled.div<{ isActive: boolean; translate: number; opacity: number }>`
  height: 100%;
  transform-origin: center center;
  transition:
    transform 300ms ease,
    opacity 300ms ease;
  transform: ${({ isActive, translate }) => (isActive ? `translateX(${translate}px) scale(1.5)` : `translateX(${translate}px) scale(0.5)`)};
  opacity: ${({ opacity }) => opacity};

  ${Device.fullHdDown} {
    transform: ${({ isActive, translate }) => (isActive ? `translateX(${translate}px) scale(1.7)` : `translateX(${translate}px) scale(0.4)`)};
  }

  ${Device.desktop} {
    transform: ${({ isActive, translate }) => (isActive ? `translateX(${translate}px) scale(1.2)` : `translateX(${translate}px) scale(0.35)`)};
  }

  ${Device.laptop} {
    transform: none;
    display: flex !important;
    justify-content: center;
  }

  &:focus {
    outline: none;
  }
`;

const CarouselImage = styled.img`
  width: auto;
  height: 300px;

  ${Device.laptop} {
    height: 350px;
  }

  ${Device.tablet} {
    width: 90%;
    height: auto;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 20px;

  ${Device.laptop} {
    padding-top: 0;
  }
`;

const ArrowButton = styled.button<{ mirror?: boolean }>`
  color: ${Colors.white};
  background: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.2s ease;
  transform: ${({ mirror }) => (mirror ? 'scaleX(-1)' : 'none')};

  &:hover {
    transform: ${({ mirror }) => (mirror ? 'scaleX(-1) scale(1.1)' : 'scale(1.1)')};
  }

  ${Device.mobile} {
    padding: 5px;
    svg {
      height: 35px;
    }
  }
`;

const StyledJumbotron = styled.div`
  width: 100%;
  height: calc(100vh - ${Sizes.HeaderHeight}px);
  max-height: 800px;
  background: ${Colors.white};

  ${Device.desktop} {
    height: auto;
  }

  ${Container} {
    flex-direction: row;
    ${Device.desktop} {
      flex-direction: column;
    }
  }
`;

const JumbotronLeft = styled.div`
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${Device.desktop} {
    width: 100%;
    margin: 40px 0;
    align-items: center;
  }
  h1 {
    font-size: 58px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-top: 0;
    color: ${Colors.primaryDark};
    ${Device.tablet} {
      font-size: 42px;
    }
  }
  p {
    margin: 0;
    font-size: 22px;
    max-width: 350px;
    ${Device.desktop} {
      max-width: none;
    }
    ${Device.tablet} {
      font-size: 18px;
      text-align: center !important;
    }
  }
`;

const JumbotronRight = styled.div`
  width: 75%;
  border-radius: 0 0 120px 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  z-index: 0;

  ${Device.desktop} {
    width: 100%;
    position: relative;
    height: auto;
    padding-top: 50px;
    padding-bottom: 30px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      background: ${Colors.primary};
      z-index: -1;
    }
  }
`;

const CarouselWrapper = styled.div`
  z-index: 2;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .slick-track {
    height: auto;
    padding-top: 200px;
    padding-bottom: 100px;

    ${Device.fullHd} {
      padding-bottom: 120px;
    }

    ${Device.desktop} {
      height: auto;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    ${Device.laptop} {
      padding-top: 0;
    }

    ${Device.mobile} {
      padding-bottom: 20px;
    }
  }
  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-dots li button:before {
    color: ${Colors.white};
    font-size: 10px;
  }
`;

const BlueStripe = styled.div`
  background: ${Colors.primary};
  border-radius: 0 0 120px 120px;
  height: calc(100% + ${Sizes.HeaderHeight}px + 120px);
  width: 580px;
  position: absolute;
  top: -${Sizes.HeaderHeight}px;
  display: flex;
  align-items: center;

  ${Device.desktop} {
    display: none;
  }
`;

const DividingLine = styled.div`
  background: ${Colors.primaryLight};
  height: 5px;
  width: 100px;
  margin-bottom: 20px;
`;
