import React from 'react';
import styled, { keyframes } from 'styled-components';
import reload from '../../assets/images/refresh.svg';
import { Colors } from '../constants/colors';
import { FormattedMessage } from 'react-intl';
import { OutlinedButton } from './buttons/OutlinedButton';
import { ThemedLogo } from './ThemedLogo';

type LoadingProps = {
  hide: boolean;
  error: boolean;
};

export const Loading = ({ error, hide }: LoadingProps) => (
  <StyledLoading className={hide ? 'hide' : ''}>
    {!error && <ThemedLogo className="animated" />}
    {error && (
      <>
        <h1>
          <FormattedMessage id="error" />
        </h1>
        <ButtonContainer>
          <OutlinedButton onClick={() => location.reload()}>
            <span>
              <FormattedMessage id="error.button" />
            </span>
            <img src={reload} alt="Reload icon" />
          </OutlinedButton>
        </ButtonContainer>
      </>
    )}
  </StyledLoading>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const ButtonContainer = styled.div`
  width: 20%;
`;

const StyledLoading = styled.div`
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;

  background: ${Colors.background};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition:
    opacity 0.2s ease-in-out,
    height 0s linear 0.2s;

  border-radius: 0.01px;
  overflow: hidden;

  &.hide {
    opacity: 0;
    height: 0;
    overflow: hidden;

    img {
      display: none;
    }
  }

  .animated {
    margin: 0;
    width: 100px;
    animation: ${rotate} 2s linear infinite;
  }

  h1 {
    margin: 0 0 40px;
  }
`;
