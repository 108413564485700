import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { PhoneIcon } from './icons/PhoneIcon';
import { MailIcon } from './icons/MailIcon';
import { ArrowIcon } from './icons/ArrowIcon';

export const SideBanner = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const showMobileBanner = window.innerWidth < 1500;

  return (
    <Banner onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)} expanded={expanded} showMobileBanner={showMobileBanner}>
      {showMobileBanner && (
        <ExpandButton onClick={() => setExpanded(!expanded)} expanded={expanded}>
          <ArrowIcon />
        </ExpandButton>
      )}
      <BannerContent showMobileBanner={showMobileBanner}>
        <BannerItem>
          <PhoneIcon />
          <Link href={'tel:+420723927536'}>+420 723 927 536</Link>
        </BannerItem>
        <BannerItem>
          <MailIcon />
          <Link href={'mailto:+info@slp.blue'}>info@slp.blue</Link>
        </BannerItem>
      </BannerContent>
    </Banner>
  );
};

const Banner = styled.div<{ expanded: boolean; showMobileBanner: boolean }>`
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  right: ${(props) => (props.expanded ? '0' : props.showMobileBanner ? '-220px' : '-160px')};
  transition: right 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${Colors.primary};
  border: 0.55px solid rgba(255, 255, 255, 0.3);
  border-right: none;
  z-index: 1000;
  border-radius: 10px 0 0 10px;
  color: ${Colors.white};
`;

const BannerContent = styled.div<{ showMobileBanner: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.showMobileBanner ? '30px' : '40px')};
  padding: ${(props) => (props.showMobileBanner ? '20px 0' : '30px 0')};
  width: 220px;
`;

const BannerItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-weight: bold;
  color: ${Colors.white};
  width: 220px;
  align-items: center;

  svg {
    width: 24px;
    margin-left: 20px;
  }
`;

const ExpandButton = styled.button<{ expanded: boolean }>`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 50px;
  width: 30px;

  svg {
    width: auto;
    height: 40px;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

const Link = styled.a`
  color: ${Colors.white};
  text-decoration: none;
`;
