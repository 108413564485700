import React from 'react';
import { Section } from '../Section';
import { Container } from '../Container';
import { ProjectSectionProps } from '../../model/ProjectSection';
import styled from 'styled-components';
import { useIsMobile, useIsNarrowerThan } from '../../helpers/useIsMobile';
import { Device, DeviceWidth } from '../../constants/device';
import { useAppContext } from '../../state/StateProvider';

export const DocumentLifecycle = (props: ProjectSectionProps) => {
  const {
    state: { project },
  } = useAppContext();

  if (!project) return null;

  const showVerticalVersion = useIsMobile();
  const showTwoRowVersion = useIsNarrowerThan(DeviceWidth.desktop) && !showVerticalVersion;
  const diagramSrc = showVerticalVersion ? project.documentLifecycle.mobileDiagram : showTwoRowVersion ? project.documentLifecycle.tabletDiagram : project.documentLifecycle.desktopDiagram;

  return (
    <Section id={'document-lifecycle'} inverse={props.inverse}>
      <Container>
        <DocumentLifeCycleContainer>
          <Diagram src={diagramSrc} alt={'document-lifecycle'} />
        </DocumentLifeCycleContainer>
      </Container>
    </Section>
  );
};

const DocumentLifeCycleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Diagram = styled.img`
  width: 100%;

  ${Device.desktop} {
    max-width: 1100px;
  }
`;
