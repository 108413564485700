import React from 'react';
import { useAppContext } from '../state/StateProvider';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import logo from '../../assets/images/mild-blue-white.svg';
import { Device } from '../constants/device';
import { OpenInNewIcon } from './icons/OpenInNewIcon';

export const Footer: React.FC = () => {
  const {
    state: { project, company },
  } = useAppContext();
  const year = new Date().getFullYear();

  if (!company || !project) {
    return null;
  }

  return (
    <StyledFooter>
      {project?.documents?.length && (
        <div>
          <div style={{ fontWeight: 'bold' }}>{project.documentsLabel}</div>
          <DocumentsContainer>
            {project.documents.map((document) => (
              <Document key={document.title}>
                <a href={document.url} target="_blank" rel="noreferrer">
                  {document.title}
                </a>
                <OpenInNewIcon />
              </Document>
            ))}
          </DocumentsContainer>
        </div>
      )}
      <CompanyInfoContainer>
        <CompanyLogo>
          <LogoHolder
            onClick={() => {
              if (company?.website) {
                window.open(company?.website, '_blank')?.focus();
              }
            }}
          >
            <img src={logo} alt={company.name + ' logo'} />
          </LogoHolder>
          <p>{company.slogan}</p>
        </CompanyLogo>
        <Divider hideOnTablet />
        <AddressContainer>
          <Address>
            <div style={{ fontWeight: 'bold' }}>{company.contactAddress}</div>
            <div>{project.company_name}</div>
            <div>{company.street}</div>
            <div>{company.postCodeAndCity}</div>
          </Address>
          <Divider />
          <Address>
            <div style={{ fontWeight: 'bold' }}>{project.billingAddress}</div>
            <div>{project.company_name}</div>
            <div>{project.street}</div>
            <div>{project.postCodeAndCity}</div>
            <div>{project.ic}</div>
          </Address>
        </AddressContainer>
      </CompanyInfoContainer>
      <FinancingContainer>
        {project.fundingNote}
        <FinancingLogos>
          <img src={'npo_logo.png'} alt={'npo_logo'} height={'40px'} />
          <img src={'ngeu_logo.png'} alt={'ngeu_logo'} height={'40px'} />
        </FinancingLogos>
      </FinancingContainer>
      <CopyrightContainer>
        <div>SLP ®</div>
        <div>
          © {year} {company.name}
        </div>
      </CopyrightContainer>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background: ${Colors.primary};
  color: ${Colors.white};
  padding: 60px 20px 30px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 40px;
`;

const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  ${Device.mobile} {
    gap: 10px;
  }
`;

const Document = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding-right: 25px;
  padding-left: 25px;

  a {
    color: ${Colors.white};
    transition: color 0.3s ease;
    text-decoration: none;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${Colors.white};
  }

  &:hover {
    a {
      text-decoration: underline;
    }
  }

  ${Device.mobile} {
    a {
      text-decoration: underline;
    }
    svg {
      display: none;
    }
  }
`;

const CompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;

  ${Device.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const CompanyLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 200px;

  img {
    max-width: 120px;
  }

  p {
    color: ${Colors.primaryLight};
    margin-bottom: 0;
  }
`;

const LogoHolder = styled.div`
  cursor: pointer;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 30px;

  ${Device.tablet} {
    width: 100%;
    gap: 10px;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 200px;
  text-align: start;

  ${Device.mobile} {
    width: none;
  }
`;

const Divider = styled.div<{ hideOnTablet?: boolean }>`
  background: ${Colors.primaryLight};
  height: 50px;
  width: 2px;
  border-radius: 5px;
  margin-top: 25px;

  ${Device.tablet} {
    display: ${(props) => (props.hideOnTablet ? 'none' : 'flex')};
  }
`;

const FinancingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

const FinancingLogos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 100px;

  ${Device.mobile} {
    gap: 30px;
  }
`;

const CopyrightContainer = styled.div`
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
`;
