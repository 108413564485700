import { ProjectSectionProps } from '../../model/ProjectSection';
import React, { ReactElement, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Section } from '../Section';
import { Container } from '../Container';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import { Colors } from '../../constants/colors';
import { useIsMobile } from '../../helpers/useIsMobile';
import { useAppContext } from '../../state/StateProvider';

type ReferenceItemProps = {
  height: number;
};

export const References = (props: ProjectSectionProps): ReactElement | null => {
  const {
    state: { project },
  } = useAppContext();
  if (!project?.references.length) return null;
  const isMobile = useIsMobile();
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: !isMobile,
    autoplay: true,
    pauseOnHover: true,
  };
  const references = project?.references;
  const [tallestSlideHeight, setTallestSlideHeight] = useState<number>(0);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);
  const imageLoadStatus = useRef(new Array(project.references.length).fill(false));

  const handleImageLoaded = (index: number) => {
    imageLoadStatus.current[index] = true;
    if (imageLoadStatus.current.every((status) => status)) {
      const heights = slideRefs.current.map((slide) => slide?.offsetHeight || 0);
      const maxHeight = Math.max(...heights);
      setTallestSlideHeight(maxHeight);
    }
  };

  return (
    <Section id={'references'} inverse={props.inverse}>
      <Container>
        <div>
          <CarouselWrapper>
            <Slider {...carouselSettings}>
              {references.map((item, index) => (
                <div key={index}>
                  <ReferenceItem ref={(el) => (slideRefs.current[index] = el)} height={tallestSlideHeight}>
                    <ReferrerInfo>
                      <ReferrerImg>
                        <img src={`/references/${item.photo}`} alt={item.photo} onLoad={() => handleImageLoaded(index)} />
                      </ReferrerImg>
                      <h3>{item.name}</h3>
                      <div>{item.position}</div>
                      <CompanyText>{item.company}</CompanyText>
                    </ReferrerInfo>
                    <ReferenceText>{`"${item.text}"`}</ReferenceText>
                  </ReferenceItem>
                </div>
              ))}
            </Slider>
          </CarouselWrapper>
        </div>
      </Container>
    </Section>
  );
};

const ReferenceText = styled.div`
  flex: 1;
  font-style: italic;
  display: flex;
  align-items: center;

  @media (max-width: 726px) {
    text-align: center;
    align-items: start;
  }
`;

const CompanyText = styled.div`
  font-weight: 600;
`;

const ReferenceItem = styled.div<ReferenceItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 40px;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};

  @media (max-width: 726px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const ReferrerInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  div {
    text-align: center;
  }

  @media (max-width: 726px) {
    padding-right: 0;
  }
`;

const ReferrerImg = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const CarouselWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;

  .slick-slide > div {
    padding: 30px;
    background: ${Colors.primary};
    color: ${Colors.white};
    border-radius: 20px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    z-index: 1;
  }

  @media (max-width: 726px) {
    padding: 0;
    .slick-prev,
    .slick-next {
      display: none;
    }
  }

  .slick-prev {
    left: -35px;
  }

  .slick-next {
    right: -35px;
  }

  .slick-prev:before,
  .slick-next:before {
    opacity: 1;
    color: ${Colors.primary};
    font-size: 30px;
  }

  .slick-prev:hover:before,
  .slick-next:hover:before {
    color: ${Colors.primaryDark};
  }

  .slick-dots li button:before {
    font-size: 10px;
    opacity: 0.25;
    color: ${Colors.primary};
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: ${Colors.primary};
  }

  .slick-dots li button:hover:before {
    opacity: 1;
    color: ${Colors.primaryDark};
  }
`;
